export default function AddTalentForm() {
  return (
    <div className="flex flex-col items-center">
      <form className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-white"
            >
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-white"
            >
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-white"
            >
              Phone
            </label>
            <div className="mt-2">
              <input
                type="tel"
                name="phone"
                id="phone"
                autoComplete="tel"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="bio"
              className="block text-sm font-medium leading-6 text-white"
            >
              Bio
            </label>
            <div className="mt-2">
              <textarea
                id="bio"
                name="bio"
                rows="3"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>

          <div className="col-span-6">
            <label className="block text-sm font-medium leading-6 text-white">
              Formats available
            </label>
            <div className="mt-2">
              <textarea
                rows="3"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="twitter"
              className="block text-sm font-medium leading-6 text-white"
            >
              X Handle
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="twitter"
                id="twitter"
                autoComplete="url"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="facebook"
              className="block text-sm font-medium leading-6 text-white"
            >
              Facebook URL
            </label>
            <div className="mt-2">
              <input
                type="url"
                name="facebook"
                id="facebook"
                autoComplete="url"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="instagram"
              className="block text-sm font-medium leading-6 text-white"
            >
              TikTok URL
            </label>
            <div className="mt-2">
              <input
                type="url"
                name="instagram"
                id="instagram"
                autoComplete="url"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="photo"
              className="block text-sm font-medium leading-6 text-white"
            >
              Upload a Photo
            </label>
            <div className="mt-2">
              <input
                type="file"
                name="photo"
                id="photo"
                accept="image/*"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="audio"
              className="block text-sm font-medium leading-6 text-white"
            >
              Upload Your Audio Demo
            </label>
            <div className="mt-2">
              <input
                type="file"
                name="audio"
                id="audio"
                accept="audio/*"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="subscription"
              className="block text-sm font-medium leading-6 text-white"
            >
              Booking
            </label>
            <div className="mt-2">
              <select
                id="subscription"
                name="subscription"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
              >
                <option>One Off</option>
                <option>Voice Tracking</option>
                <option>Exclusive</option>
              </select>
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="currency"
              className="block text-sm font-medium leading-6 text-white"
            >
              Currency
            </label>
            <div className="mt-2">
              <select
                id="currency"
                name="currency"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
              >
                <option>USD</option>
                <option>EUR</option>
                <option>GBP</option>
              </select>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
