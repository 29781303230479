import React from "react";
import Pricing from "../components/pricing";
import AddTalentForm from "../components/addTalentForm";
import HeroSection from "../components/heroSection";

export default function NewTalent() {
  return (
    <>
      <HeroSection />
      <Pricing />
      <AddTalentForm />
    </>
  );
}
