import React from "react";
import HeroSection from "../components/heroSection";
import Stats from "../components/stats";
import FAQComponent from "../components/faq";

export default function Home() {
  return (
    <>
      <HeroSection />
      <Stats />
      <FAQComponent />
    </>
  );
}
