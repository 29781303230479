import React, { useRef } from "react";

const HeroSection = () => {
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  return (
    <div>
      <div className="relative overflow-hidden md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <div className="relative" style={{ paddingBottom: "56.25%" }}>
          <video
            ref={videoRef}
            autoPlay
            muted
            loop
            id="hero-video"
            className="object-cover w-full h-full sm:rounded-2xl absolute top-40 left-0 bg-gray-900"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              objectFit: "cover",
            }}
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2Faivtloop.mp4?alt=media&token=ff985674-0cb1-4e9e-8e33-f42e9079a74e"
              type="video/mp4"
            />
          </video>
          <div className="absolute inset-auto bg-gradient-to-r from-blue-200 to-blue-400 mix-blend-multiply" />
        </div>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            AI Voice Talents are here
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Ready to Use your voice ?
          </p>
          <p className="mt-6 text-base leading-7 text-gray-300">
		  Imagine accessing a vast array of professional voices, poised to infuse life into your narrations, adverts, and On Air shifts. With the integration of smart contracts and blockchain, we ensure transparent monitoring of usage, enabling direct and secure payments to talent. Welcome to the revolutionary era of voice solutions where quality, convenience, and trustworthiness harmonize seamlessly!
          </p>
          <div className="mt-8">
            <button
              onClick={playVideo}
              className="inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Ready to start?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
