import { BsFillMicFill, BsSearch, BsChatText } from "react-icons/bs";

const stats = [
  {
    id: 1,
    name: "your voice works even when you don't",
    value: "Easy process to become AI voice",
    Icon: BsFillMicFill,
  },
  {
    id: 2,
    name: "for over 1000+ radio stations",
    value: "150+ voices available",
    Icon: BsSearch,
  },
  { id: 3, name: "Contact your voice provider directly", value: "Chat available", Icon: BsChatText },
];

export default function Stats() {
  return (
    <div className="bg-violet-300 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4 items-center"
            >
              <stat.Icon className="w-8 h-8 text-gray-900 mb-4" />
              <dt className="text-base leading-7 text-gray-800">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
