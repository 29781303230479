import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/layout";
import Home from "./pages/home";
import Talent from "./pages/talent";
import NewTalent from "./pages/addTalent";
import Profile from "./pages/profile";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/new-talent" element={<NewTalent />} />
          <Route path="/profile/:profileName" element={<Profile />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
