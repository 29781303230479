const faqs = [
  {
    id: 1,
    question: "How does this work?",
    answer:
      "Simply magic",
  },
  {
    id: 2,
    question: "Can I really make money with this?",
    answer:
      "Probably, if you don't suck",
  },
  {
    id: 3,
    question: "How can I use this at my station?",
    answer:
      "Yes",
  },
  {
    id: 4,
    question: "I need promo done now and it's 2AM?",
    answer:
      "What stops you? our voice is available right NOW!",
  },
];

export default function FAQComponents() {
  return (
    <div className="bg-gray-200">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-700">
            Have a different question and can’t find the answer you’re looking
            for? Reach out to our support team by{" "}
            <a
              href="#"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              sending us an email
            </a>{" "}
            and we’ll get back to you as soon as we can.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-800">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-700">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
