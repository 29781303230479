import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";

const Waveform = ({ audioUrl }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (audioUrl) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "blue",
        progressColor: "red",
        height: 128,
        barWidth: 3,
      });

      wavesurfer.current.load(audioUrl);

      return () => {
        wavesurfer.current.destroy();
      };
    }
  }, [audioUrl]);

  const togglePlay = () => {
    if (isPlaying) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <div className="mt-10">
        <button
          type="submit"
          className="flex w-full items-center justify-center rounded-md border
          border-transparent bg-indigo-600 px-8 py-3 text-base font-medium
          text-white hover:bg-indigo-700 focus:outline-none focus:ring-2
          focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          onClick={togglePlay}
        >
          {isPlaying ? "Pause" : "Play"} the audio
        </button>
      </div>
      <div ref={waveformRef} />
    </div>
  );
};

export default Waveform;
